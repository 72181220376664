import React, { useState, useEffect, useContext, useRef } from "react"
import styled, { css } from "styled-components"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

// Utils
import useScrollDirection from "../utils/useScrollDirection"
import handleAnchorLink from "../utils/handleAnchorLink"
import { getNavItems, paths } from "../constants/paths"

// Context
import AppContext from "../context/AppContext"

// Styling
import colors, { shadows } from "../styles/colors"
import { slowTransition, fastTransition } from "../styles/animations"
import mq, { sizes } from "../styles/breakpoints"
import textStyles from "../styles/textStyles"

// Components
import Logo from "./Logo"
import Link from "./atoms/Link"
import Button from "./atoms/Button"
import LocaleSwitcher from "./LocaleSwitcher"

interface IProps {
  isLight?: boolean
  locationPathname: string
}

const getLinkColor = (isLight: boolean) =>
  isLight ? colors.whiteGrey : undefined

const Navigation: React.FC<IProps> = ({
  locationPathname,
  isLight = false,
}) => {
  const context = useContext(AppContext)
  const { getLocalizedLink, isDutch } = useLocale()
  const scrollDirection = useScrollDirection()

  const mobileNavRef = useRef<HTMLDivElement>(null)

  const [viewportWidth, setViewportWidth] = useState<number>(0)
  const [openMobileNav, setOpenMobileNav] = useState<boolean>(false)

  const handleViewportWidth = () => {
    if (window !== undefined) {
      setViewportWidth(window.innerWidth)
    }
  }

  useEffect(() => {
    handleViewportWidth()
    window.addEventListener("resize", handleViewportWidth)

    return () => {
      if (window !== undefined) {
        window.removeEventListener("resize", handleViewportWidth)
      }
    }
  }, [])

  // Close mobile navigation when a screen resized back to desktop size
  useEffect(() => {
    if (viewportWidth >= sizes.M) {
      setOpenMobileNav(false)
    }
  }, [viewportWidth])

  useEffect(() => {
    if (mobileNavRef.current) {
      if (openMobileNav) {
        disableBodyScroll(mobileNavRef.current)
      } else {
        enableBodyScroll(mobileNavRef.current)
      }
    }
    return () => clearAllBodyScrollLocks()
  }, [openMobileNav])

  const handleLogoLinkClick = () => {
    if (viewportWidth < sizes.M) {
      setOpenMobileNav(false)
    }
  }

  const handleMobileAnchorNavClick = (
    page: string,
    to: string,
    setScrollToAnchorContext: (element: string) => void
  ) => {
    setOpenMobileNav(false)
    handleAnchorLink(page, to, setScrollToAnchorContext)
  }

  const handleMobileDemoNavClick = () => {
    setOpenMobileNav(false)
  }

  const handleShowNav = () => {
    if (context?.hideNav === false) {
      if (openMobileNav === false && scrollDirection === "down") {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  return (
    <Container show={handleShowNav()}>
      <MenubarWrapper>
        <StyledLogoLink
          type="anchor"
          to="intro"
          page={getLocalizedLink("/")}
          hasstyling={false}
          onClick={handleLogoLinkClick}
        >
          <StyledLogo withText />
        </StyledLogoLink>
        {viewportWidth >= sizes.M && (
          <DesktopLinks>
            {getNavItems(isDutch).map((item) => {
              switch (item.type) {
                case "anchor":
                  return (
                    <AnchorLink
                      type="anchor"
                      key={`desktop-${item.type}-${item.title}`}
                      to={item.to}
                      title={item.title}
                      page="/"
                      color={getLinkColor(isLight)}
                    />
                  )
                case "demo":
                  return (
                    <StyledButton
                      key="desktop-book-a-demo-button"
                      label="Get in touch"
                      variant="primary"
                      href={paths.demo}
                    />
                  )
                case "internal":
                  return (
                    <StyledLink
                      key={`desktop-${item.type}-${item.title}`}
                      type="internal"
                      to={item.to}
                      title={item.title}
                    />
                  )
                case "localization":
                  return (
                    <StyledLocaleSwitcherDesktop
                      key={`mobile-${item.type}-${item.title}`}
                      currentPathname={locationPathname}
                    />
                  )
                case "external":
                default:
                  return (
                    <StyledButton
                      key={`desktop-${item.type}-${item.title}`}
                      variant="secondary"
                      label={item.title || "Log in"}
                      href={item.to}
                      isExternal
                    />
                  )
              }
            })}
          </DesktopLinks>
        )}
        {viewportWidth < sizes.M && (
          <MobileLinks open={openMobileNav} ref={mobileNavRef}>
            {getNavItems(isDutch).map((item) => {
              switch (item.type) {
                case "anchor":
                  return (
                    <MobileAnchorLink
                      key={`mobile-${item.type}-${item.title}`}
                      type="nolink"
                      onClick={() =>
                        handleMobileAnchorNavClick(
                          "/",
                          item.to as string,
                          context?.setScrollToAnchor as (
                            element: string
                          ) => void
                        )
                      }
                      page={getLocalizedLink("/")}
                    >
                      {item.title}
                    </MobileAnchorLink>
                  )
                case "demo":
                  return (
                    <StyledButton
                      key={`mobile-book-a-demo-button`}
                      label="Get in touch"
                      href={getLocalizedLink(paths.demo)}
                      onClick={() => handleMobileDemoNavClick()}
                    />
                  )
                case "internal":
                  return (
                    <StyledLink
                      key={`mobile-${item.type}-${item.title}`}
                      type="internal"
                      to={item.to}
                      title={item.title}
                      onClick={() => setOpenMobileNav(false)}
                    />
                  )
                case "localization":
                  return (
                    <StyledLocaleSwitcherMobile
                      key={`mobile-${item.type}-${item.title}`}
                      currentPathname={locationPathname}
                    />
                  )
                case "external":
                default:
                  return (
                    <StyledButton
                      fullWidth={false}
                      key={`desktop-${item.type}-${item.title}`}
                      label={item.title || "Log in"}
                      href={item.to || "/"}
                    />
                  )
              }
            })}
          </MobileLinks>
        )}
        {viewportWidth < sizes.M && (
          <MobileButton onClick={() => setOpenMobileNav(!openMobileNav)}>
            <Icon />
          </MobileButton>
        )}
      </MenubarWrapper>
    </Container>
  )
}

const StyledLocaleSwitcherDesktop = styled(LocaleSwitcher)`
  margin-right: 32px;
`
const StyledLocaleSwitcherMobile = styled(LocaleSwitcher)`
  margin-bottom: 32px;

  ${mq.to.M`
    font-size: 16px;
  `}
`

// Shared styles
const sharedLinkStyles = css`
  ${textStyles.body};
  color: ${colors.text};
  font-weight: 500;

  :not(:last-child) {
    margin-bottom: 24px;
  }

  ${mq.from.S`
    :not(:last-child) {
      margin-bottom: 32px;
    }
  `}

  ${mq.from.M`
    :not(:last-child) {
      margin-bottom: 0;
    }

    :not(:last-child) {
      margin-right: 24px;
    }
  `}
`

// Main styles
const Container = styled.nav`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 0 0;
  transition: transform ${slowTransition};

  ${mq.from.S`
    padding: 16px 0;
  `}

  ${mq.from.M`
    ${shadows.depthThree};
  `}

  ${mq.from.M`
    background-color: ${colors.white};
  `}

  ${({ show }: { show: boolean }) =>
    !show &&
    `
    transform: translateY(-100%);
  `};
`

const MenubarWrapper = styled.div`
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 24px;

  ${mq.from.S`
    padding: 0 64px;
  `}

  ${mq.from.L`
    padding: 0 128px;
  `}
`

const StyledLogoLink = styled(Link)`
  z-index: 1;
  display: flex;
  align-items: flex-start;
`

const StyledLogo = styled(Logo)`
  width: 96px;

  ${mq.from.M`
    width: 128px;
  `}
`

const AnchorLink = styled(Link)`
  margin-right: 32px;
`

// Desktop
const DesktopLinks = styled.div`
  display: flex;
  align-items: center;
`

// Mobile
const MobileButton = styled.button`
  cursor: pointer;
  z-index: 1;
  position: relative;
  border: none;
  background: none;
  transition: transform ${fastTransition};
  outline: none;
  padding: 0;
  width: 20px;
  height: 20px;

  :hover {
    transform: scale(1.1);
  }
`
const Icon = styled.div`
  width: 16px;
  height: 3px;
  background-color: ${colors.text};
  border-radius: 16px;

  :before,
  :after {
    content: "";
    position: absolute;
    height: 3px;
    background-color: ${colors.text};
    border-radius: 16px;
    width: 20px;
    left: 0;
  }

  :before {
    top: 2px;
  }

  :after {
    bottom: 1px;
  }
`

const MobileLinks = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${colors.white};
  opacity: 0;
  transition: opacity ${fastTransition};
  pointer-events: none;

  ${({ open }: { open: boolean }) =>
    open &&
    `
    opacity: 1;
    pointer-events: auto;
  `}
`

const MobileAnchorLink = styled(Link)`
  ${sharedLinkStyles}
  cursor: pointer;
`

const StyledButton = styled(Button)`
  margin: 0 auto;
  :not(:last-child) {
    margin-bottom: 16px;
  }

  ${mq.from.S`
    :not(:last-child) {
      margin-bottom: 24px;
    }
  `}

  ${mq.from.M`
  :not(:last-child) {
    margin-bottom: 0;
  }

  :not(:last-child) {
    margin-right: 16px;
  }
`}
`

const StyledLink = styled(Link)`
  ${sharedLinkStyles}

  &:after {
    display: none;
  }

  &:hover {
    color: ${colors.purpleLight};
  }

  &.active {
    color: ${colors.purpleLight};
  }
`

export default Navigation
