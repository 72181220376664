import React from "react"

// Styling
import colors from "../styles/colors"

interface IProps {
  simple?: boolean
  withText?: boolean
}

const Logo: React.FCS<IProps> = ({
  simple = false,
  className,
  withText = true,
}) =>
  withText ? (
    <svg
      className={className}
      width="133"
      height="32"
      viewBox="0 0 133 32"
      fill={simple ? colors.white : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.3532 24.63C48.9407 24.63 47.5698 24.4555 46.2404 24.1065C44.911 23.7576 43.8309 23.2923 43 22.7107L44.6202 19.0715C45.4012 19.5866 46.3068 20.0021 47.3371 20.3178C48.3674 20.6335 49.381 20.7914 50.3781 20.7914C52.2725 20.7914 53.2196 20.3178 53.2196 19.3706C53.2196 18.8721 52.9455 18.5065 52.3971 18.2739C51.8653 18.0246 51.0012 17.767 49.8048 17.5012C48.492 17.2187 47.3953 16.9196 46.5146 16.6038C45.6338 16.2715 44.8778 15.748 44.2463 15.0335C43.6148 14.3189 43.2991 13.3551 43.2991 12.1421C43.2991 11.0786 43.5899 10.1231 44.1715 9.27559C44.7531 8.41149 45.6172 7.73018 46.7638 7.23167C47.927 6.73315 49.3478 6.48389 51.0262 6.48389C52.1727 6.48389 53.3027 6.61683 54.4161 6.8827C55.5294 7.13196 56.5099 7.50585 57.3573 8.00437L55.8369 11.6685C54.1751 10.7712 52.5633 10.3225 51.0012 10.3225C50.0208 10.3225 49.3063 10.472 48.8576 10.7711C48.4089 11.0536 48.1846 11.4275 48.1846 11.8928C48.1846 12.3581 48.4505 12.7071 48.9822 12.9397C49.514 13.1724 50.3698 13.4133 51.5496 13.6626C52.879 13.9451 53.9757 14.2525 54.8398 14.5848C55.7205 14.9005 56.4766 15.4157 57.1081 16.1302C57.7562 16.8282 58.0802 17.7836 58.0802 18.9967C58.0802 20.0436 57.7894 20.9908 57.2078 21.8383C56.6262 22.6858 55.7538 23.3671 54.5906 23.8822C53.4274 24.3807 52.0149 24.63 50.3532 24.63Z"
        fill={simple ? colors.white : colors.main["200"]}
      />
      <path
        d="M71.4021 24.63C68.8098 24.63 66.7908 23.9237 65.3451 22.5113C63.916 21.0988 63.2015 19.0964 63.2015 16.5041V6.83285H68.1368V16.3546C68.1368 19.1463 69.2419 20.5421 71.452 20.5421C73.6455 20.5421 74.7422 19.1463 74.7422 16.3546V6.83285H79.6028V16.5041C79.6028 19.0964 78.8799 21.0988 77.4342 22.5113C76.0051 23.9237 73.9944 24.63 71.4021 24.63Z"
        fill={simple ? colors.white : colors.main["200"]}
      />
      <path
        d="M101.69 24.281L101.641 15.0335L97.1539 22.5611H94.9605L90.4987 15.2827V24.281H85.9373V6.83285H90.0002L96.132 16.9029L102.114 6.83285H106.177L106.227 24.281H101.69Z"
        fill={simple ? colors.white : colors.main["200"]}
      />
      <path
        d="M128.463 24.281L128.414 15.0335L123.927 22.5611H121.733L117.272 15.2827V24.281H112.71V6.83285H116.773L122.905 16.9029L128.887 6.83285H132.95L133 24.281H128.463Z"
        fill={simple ? colors.white : colors.main["200"]}
      />
      <path
        d="M15.1134 16.9033H5.42084L2.00082 20.3233C-0.666942 22.9911 -0.666942 27.3152 2.00082 29.9829C4.66859 32.6507 8.99358 32.6507 11.6604 29.9829L15.1125 26.5308V16.9033H15.1134Z"
        fill={simple ? colors.white : colors.main["300"]}
      />
      <path
        d="M25.1692 2.35918C26.3683 2.35918 27.4957 2.8258 28.3432 3.67421C30.0938 5.42476 30.0938 8.27257 28.3432 10.0222L25.6415 12.7239H19.2615V6.40796L21.9952 3.67421C22.8427 2.82675 23.9701 2.35918 25.1692 2.35918ZM25.1692 0.0175781C23.4215 0.0175781 21.6728 0.684049 20.339 2.01793L16.9189 5.43795V15.0655H26.6115L29.9985 11.6785C32.6663 9.01069 32.6663 4.6857 29.9985 2.01793C28.6647 0.684049 26.9169 0.0175781 25.1683 0.0175781H25.1692Z"
        fill={simple ? colors.white : colors.main["300"]}
      />
      <path
        d="M29.9995 29.9993C32.6673 27.3316 32.6673 23.0066 29.9995 20.3388L26.5474 16.8877C26.4447 16.9282 26.3334 16.9518 26.2165 16.9518H16.9189V26.5793L20.339 29.9993C23.0067 32.6671 27.3317 32.6671 29.9985 29.9993H29.9995Z"
        fill={simple ? colors.white : colors.purpleLighter}
      />
      <path
        d="M15.1134 15.0814V5.78377C15.1134 5.68479 15.1332 5.59147 15.1625 5.50192L11.6614 2.00082C8.99361 -0.666942 4.66863 -0.666942 2.0018 2.00082C-0.665965 4.66859 -0.665965 8.99358 2.0018 11.6604L5.42182 15.0804H15.1144L15.1134 15.0814Z"
        fill={simple ? colors.white : colors.purpleLight}
      />
    </svg>
  ) : (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={simple ? colors.white : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1134 16.9033H5.42084L2.00082 20.3233C-0.666942 22.9911 -0.666942 27.3152 2.00082 29.9829C4.66859 32.6507 8.99358 32.6507 11.6604 29.9829L15.1125 26.5308V16.9033H15.1134Z"
        fill={simple ? colors.white : colors.main["300"]}
      />
      <path
        d="M25.1692 2.35918C26.3683 2.35918 27.4957 2.8258 28.3432 3.67421C30.0938 5.42476 30.0938 8.27257 28.3432 10.0222L25.6415 12.7239H19.2615V6.40796L21.9952 3.67421C22.8427 2.82675 23.9701 2.35918 25.1692 2.35918ZM25.1692 0.0175781C23.4215 0.0175781 21.6728 0.684049 20.339 2.01793L16.9189 5.43795V15.0655H26.6115L29.9985 11.6785C32.6663 9.01069 32.6663 4.6857 29.9985 2.01793C28.6647 0.684049 26.9169 0.0175781 25.1683 0.0175781H25.1692Z"
        fill={simple ? colors.white : colors.main["300"]}
      />
      <path
        d="M29.9995 29.9993C32.6673 27.3316 32.6673 23.0066 29.9995 20.3388L26.5474 16.8877C26.4447 16.9282 26.3334 16.9518 26.2165 16.9518H16.9189V26.5793L20.339 29.9993C23.0067 32.6671 27.3317 32.6671 29.9985 29.9993H29.9995Z"
        fill={simple ? colors.white : colors.purpleLighter}
      />
      <path
        d="M15.1134 15.0814V5.78377C15.1134 5.68479 15.1332 5.59147 15.1625 5.50192L11.6614 2.00082C8.99361 -0.666942 4.66863 -0.666942 2.0018 2.00082C-0.665965 4.66859 -0.665965 8.99358 2.0018 11.6604L5.42182 15.0804H15.1144L15.1134 15.0814Z"
        fill={simple ? colors.white : colors.purpleLight}
      />
    </svg>
  )

export default Logo
