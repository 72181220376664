import React from "react"
import styled from "styled-components"

// Types
import { IIconProps } from "./Icon"

// Styles
import colors from "../../styles/colors"

const ArrowRight: React.FCS<IIconProps> = ({
  className,
  width = 17,
  height = 12,
  color = colors.purpleLight,
  direction = "right",
}) => (
  <Svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 24 24"
    direction={direction}
    className={className}
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3684 17.5176C10.7727 18.1271 10.7727 19.1152 11.3684 19.7247C11.964 20.3341 12.9298 20.3341 13.5255 19.7247L19.5502 13.5606C20.3926 12.6987 20.3926 11.3012 19.5502 10.4393L13.5255 4.27521C12.9298 3.66575 11.964 3.66575 11.3684 4.27521C10.7727 4.88468 10.7727 5.87281 11.3684 6.48228L14.8179 10.0116C14.9877 10.1854 14.8674 10.4826 14.6272 10.4826L5.3014 10.4826C4.48234 10.4826 3.81836 11.1619 3.81836 11.9999C3.81836 12.8379 4.48234 13.5173 5.3014 13.5173L14.6272 13.5173C14.8674 13.5173 14.9877 13.8145 14.8179 13.9882L11.3684 17.5176Z"
      fill={color}
    />
  </Svg>
)

const Svg = styled.svg<{
  direction: "right" | "left" | "down" | "up" | undefined
}>`
  ${({ direction }) =>
    direction === "left" &&
    `
    transform: rotate(180deg);
  `};
`

export default ArrowRight
