// Global stylesheet
import { createGlobalStyle } from "styled-components"

// Styling
import colors from "./colors"
import textStyles from "./textStyles"
import { fonts } from "./fonts"

const GlobalStyle = createGlobalStyle`


  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    color: ${colors.text};
    background-color: ${colors.backgroundPurple};
    font-family: ${fonts.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1 {
    letter-spacing: -2px;
  }
  h2,h3,h4,h5 {
    letter-spacing: -1px;
  }

  p {
    ${textStyles.body};
  }
`

export default GlobalStyle
