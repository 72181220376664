import React from "react"
import styled, { css } from "styled-components"
import { StaticQuery, graphql } from "gatsby"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { fastTransition } from "../styles/animations"
import mq from "../styles/breakpoints"

// Constants
import { paths } from "../constants/paths"

// Components
import ContentWrapper from "../components/ContentWrapper"
import Logo from "../components/Logo"
import Icon from "../components/icons/Icon"
import Link from "../components/atoms/Link"
import Button from "./atoms/Button"
import LocaleSwitcher from "./LocaleSwitcher"

interface IProps {
  locationPathname: string
}
const Footer: React.FC<IProps> = ({ locationPathname }) => {
  const { isDutch, getLocalizedLink } = useLocale()

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          prismicHomePage {
            data {
              footer_socials {
                type
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        const footerData = {
          socials: data.prismicHomePage?.data?.footer_socials,
        }

        return (
          <Container>
            <StyledContentWrapper>
              <Section>
                <Link
                  type="anchor"
                  to="intro"
                  page={getLocalizedLink("/")}
                  hasstyling={false}
                >
                  <StyledLogo simple />
                </Link>
                <Bottom>
                  <Address>
                    Gedempt Hamerkanaal 33 <br />
                    1021KL Amsterdam
                  </Address>
                  <StyledLink
                    type="external"
                    title="hello@getsumm.co"
                    to="mailto:hello@getsumm.co"
                    color={colors.white}
                  />
                  {footerData?.socials?.length > 0 && (
                    <Socials>
                      {footerData.socials.map((item: any) => (
                        <Social
                          key={item.type.toLocaleLowerCase()}
                          type="external"
                          target="_blank"
                          to={item.url}
                          hasstyling={false}
                        >
                          <Icon
                            icon={
                              item.type.toLocaleLowerCase() as
                                | "instagram"
                                | "facebook"
                                | "twitter"
                                | "linkedin"
                            }
                            width={32}
                            color={colors.white}
                          />
                        </Social>
                      ))}
                    </Socials>
                  )}
                </Bottom>
              </Section>
              <Section line>
                <BoldLink
                  type="internal"
                  title="Home"
                  to={paths.home}
                  color={colors.white}
                />
                <BoldLink
                  type="internal"
                  title={isDutch ? "Methode" : "Method"}
                  to={paths.method}
                  color={colors.white}
                />
                <BoldLink
                  type="internal"
                  title={isDutch ? "Diensten" : "Services"}
                  to={paths.services}
                  color={colors.white}
                />
                <BoldLink
                  type="internal"
                  title="Platform"
                  to={paths.platform}
                  color={colors.white}
                />
                <BoldLink
                  type="internal"
                  title="Resources"
                  to={paths.resources}
                  color={colors.white}
                />
                <BoldLink
                  type="internal"
                  title="Blog"
                  to={paths.blog}
                  color={colors.white}
                />
                <BoldLink
                  type="internal"
                  title={isDutch ? "Ons verhaal" : "About"}
                  to={paths.about}
                  color={colors.white}
                />
              </Section>
              <Section line>
                <BoldLink
                  type="internal"
                  title="FAQ"
                  to={paths.faq}
                  color={colors.white}
                />
                <BoldLink
                  type="internal"
                  to={paths.privacy}
                  title="Privacy policy"
                  color={colors.white}
                />
              </Section>
              <Section>
                <StyledButton
                  label="Login"
                  variant="tertiary"
                  href={"https://app.getsumm.co"}
                  isExternal
                />

                <Bottom>
                  <StyledLocaleSwitcher
                    context="footer"
                    currentPathname={locationPathname}
                  />
                  &copy; 2010 – {new Date().getFullYear()}
                </Bottom>
              </Section>
            </StyledContentWrapper>
          </Container>
        )
      }}
    />
  )
}

const Address = styled.p`
  ${textStyles.accent};
  margin: 0 0 8px;
  color: ${colors.white};
  font-weight: 400;
`

const StyledLocaleSwitcher = styled(LocaleSwitcher)`
  margin-top: 8px;

  ${mq.from.M`
  font-size: 16px;
  `}
`

const Container = styled.footer`
  color: ${colors.white};
  background-color: ${colors.textLight};
`

const StyledContentWrapper = styled(ContentWrapper)`
  padding-top: 56px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;

  ${mq.from.M`
    padding-top: 80px;
    padding-bottom: 80px;
    flex-direction: row;
    justify-content: space-between;
  `}
`

const Section = styled.div<{ line?: boolean }>`
  flex-direction: column;
  display: flex;
  margin-bottom: 56px;
  ${({ line }) =>
    line &&
    `
    padding-left: 24px;
    border-left: 1px solid ${colors.white};
    align-self: flex-start;
  `}

  ${mq.from.M`
    margin-bottom: 0;
  `}
`

const StyledLogo = styled(Logo)`
  width: 104px;
  margin-bottom: 8px;
`

const Bottom = styled.div`
  ${textStyles.accent};
  margin-top: 24px;

  ${mq.from.M`
    margin-top: auto;
  `}
`

const Socials = styled.div`
  display: flex;
  margin-top: 12px;
`

const Social = styled(Link)`
  margin-right: 12px;

  :last-of-type {
    margin-right: 0;
  }
`

const sharedLinkStyles = css`
  ${textStyles.accent};
  transition: color ${fastTransition};

  ${mq.from.M`
      font-size: 18px;
  `}

  &:after {
    display: none;
  }

  &:hover {
    color: ${colors.whitePurple};
  }
`

const BoldLink = styled(Link)`
  ${sharedLinkStyles};
  margin-bottom: 24px;
  font-weight: 500;

  :last-of-type {
    margin-bottom: 0;
  }
`

const StyledLink = styled(Link)`
  ${sharedLinkStyles};
  margin-bottom: 16px;
  font-weight: 400;

  :last-of-type {
    margin-bottom: 0;
  }

  ${mq.from.M`
    margin-bottom: 24px;
    font-size: 16px;
  `}
`

const StyledButton = styled(Button)`
  align-self: flex-start;
`

export default Footer
