import React from "react"

// Types
import { IIconProps } from "./Icon"

const Linkedin: React.FCS<IIconProps> = ({
  className,
  width,
  height,
  color,
}) => (
  <svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 35 34"
    className={className}
    fill="none"
  >
    <path
      d="M17.5 0C7.836 0 0 7.613 0 17s7.836 17 17.5 17C27.163 34 35 26.387 35 17S27.163 0 17.5 0zm-5.085 25.7H8.153V13.242h4.262V25.7zm-2.131-14.158h-.028c-1.43 0-2.355-.956-2.355-2.151 0-1.223.953-2.153 2.411-2.153 1.458 0 2.355.93 2.383 2.153 0 1.195-.925 2.151-2.411 2.151zM27.783 25.7H23.52v-6.664c0-1.674-.617-2.816-2.159-2.816-1.177 0-1.878.77-2.187 1.514-.112.266-.14.638-.14 1.01V25.7h-4.262s.056-11.287 0-12.456h4.262v1.764c.567-.849 1.58-2.056 3.841-2.056 2.804 0 4.907 1.78 4.907 5.606v7.142z"
      fill={color}
    />
  </svg>
)

export default Linkedin
