import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Styling
import colors from "../../styles/colors"
import textStyles from "../../styles/textStyles"
import { fastTransition } from "../../styles/animations"
import mq from "../../styles/breakpoints"
import { VARIANTS } from "../../styles/button"

// Components
import Arrow from "../icons/ArrowRight"
import Download from "../icons/Download"

// Types
export type Variant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "ghost"
  | "download"
export type ColorScheme = "purple" | "white" | "yellow"
export type ButtonType = "submit" | "button"
interface IProps {
  label?: string
  variant?: Variant
  disabled?: boolean
  icon?: boolean
  href?: string
  onClick?: () => void
  type?: ButtonType
  isExternal?: boolean
  position?: "left" | "right" | "center"
  fullWidth?: boolean
}

const Button: React.FCS<IProps> = ({
  label,
  variant = "primary",
  disabled,
  className,
  icon,
  href,
  isExternal,
  position = "left",
  onClick,
  type = "button",
  fullWidth = true,
}) => {
  const [isHovered, setIsHovered] = React.useState(false)
  const activeOption = VARIANTS[variant]

  const Content = () => (
    <ButtonStyled
      variant={variant}
      color={activeOption.bg}
      labelColor={activeOption.textColor}
      hover={{
        bg: activeOption.hover.bg,
        color: activeOption.hover.textColor,
      }}
      disabledColor={{
        bg: activeOption.disabled.bg,
        color: activeOption.disabled.textColor,
      }}
      disabled={disabled}
      className={className}
      allowBoxShadow={variant !== "ghost" && variant !== "download"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onClick && onClick()}
      type={type}
    >
      {icon}
      {variant === "download" && (
        <Download
          width={16}
          height={18}
          color={
            isHovered ? activeOption.hover.textColor : activeOption.textColor
          }
        />
      )}
      {label && label}
      {icon && (
        <Arrow
          width={16}
          height={16}
          color={variant === "primary" ? colors.white : colors.purpleLight}
        />
      )}
    </ButtonStyled>
  )

  if (!!href && !!isExternal)
    return (
      <StyledLinkAnchor href={href} target="_blank" rel="noopener noreferrer">
        <Content />
      </StyledLinkAnchor>
    )
  if (!!href)
    return (
      <StyledLink position={position} fullWidth={fullWidth} to={href}>
        <Content />
      </StyledLink>
    )
  return <Content />
}

const ButtonStyled = styled.button<{
  labelColor: string
  color: string
  hover: { bg: string; color: string }
  disabledColor: { bg: string; color: string }
  disabled?: boolean
  allowBoxShadow?: boolean
  variant: Variant
}>`
  ${textStyles.body}
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 18px;
  gap: 8px;
  margin: 0;
  padding: 16px 24px;
  color: ${({ labelColor, disabled, disabledColor }) =>
    disabled ? disabledColor.color : labelColor};
  background: ${({ color, disabled, disabledColor }) =>
    disabled ? disabledColor.bg : color};
  border-radius: 8px;
  font-weight: 600;
  outline: none;
  transition: background ${fastTransition};
  box-shadow: ${({ allowBoxShadow }) =>
    allowBoxShadow ? "0px 1px 0px rgba(42, 9, 134, 0.1)" : "none"};
  border: ${({ variant, hover }) =>
    variant === "download"
      ? `2px solid ${hover.color}`
      : "2px solid transparent"}};

  ${mq.from.M`
    line-height: 18px;
  `}

  &:hover {
    background: ${({ hover, disabled, disabledColor }) =>
      disabled ? disabledColor.bg : hover.bg};
      color: ${({ hover, disabled, disabledColor }) =>
        disabled ? disabledColor.color : hover.color};
      border: ${({ variant, hover }) =>
        variant === "download"
          ? `2px solid ${hover.color}`
          : "2px solid transparent"}};
    };
    
  };
  }
`

const StyledLink = styled(Link)<{
  position: "left" | "right" | "center"
  fullWidth: boolean
}>`
  text-decoration: inherit;
  color: inherit;

  margin: ${({ position }) =>
    position === "right" ? "0 0 0 auto" : position === "left" ? "" : "0 auto"};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "")};
`

const StyledLinkAnchor = styled.a`
  text-decoration: inherit;
  color: inherit;
  flex: 1;
`

export default Button
