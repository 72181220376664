export const shadows = {
  depthOne:
    "box-shadow: 0px 0px 3px rgba(42, 9, 134, 0.05), 0px 8px 20px rgba(42, 9, 134, 0.06);",
  depthTwo:
    "box-shadow: 0px 2px 1px rgba(42, 9, 134, 0.05), 0px 0px 1px rgba(42, 9, 134, 0.25);",
  depthThree:
    "box-shadow: 0px 0px 2px rgba(42, 9, 134, 0.2), 0px 2px 10px rgba(42, 9, 134, 0.1);",
  depthFour:
    "box-shadow: 0px 0px 3px rgba(42, 9, 134, 0.1), 0px 4px 20px rgba(42, 9, 134, 0.15);",
  depthFive:
    "box-shadow: 0px 0px 4px rgba(42, 9, 134, 0.08), 0px 8px 40px rgba(42, 9, 134, 0.16);",
  depthSix:
    "box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.12), 0px 8px 20px rgba(54, 42, 102, 0.5);",
}

export default {
  main: {
    "100": "#1D1636",
    "200": "#362A66",
    "300": "#5C47AE",
    "400": "#8C7CCA",
    "500": "#A69AD6",
    "700": "#EFEDF8",
    "800": "#F8F5FE",
  },
  actionUI: "#2A0986",
  black: "#000000",
  white: "#FFFFFF",
  OffWhite: "#ffffffe6",
  whiteGrey: "#F8F5FE",
  whitePurple: "#CCC5E7",
  greyLight: "#F9FAFB",
  grey: "#BCBEC0",
  grayLight: "#f4f2f8",
  greyHighlight: "#E0E3E8",
  blueGrey: "#474F5A",
  text: "#000000",
  textLight: "#5C47AE", // TODO: same as main.300
  backgroundYellow: "#FFFEFA",
  backgroundBlue: "#FAFDFF",
  backgroundPurple: "#FCFBFE",
  grayPurpleLight: "#F9F8FC",
  pink: "#DD33FF",
  purpleFake: "#7F74AB",
  purpleLightest: "#F8F5FE",
  purpleLighter: "#A182F7",
  purpleLight: "#6F3FF3",
  purpleLightDim: "#6f3ff380",
  purpleSoft: "#D4D4FC",
  purple: "#503E98", // TODO: should be main.300
  purpleDim: "#f5d9fd",
  purpleDarkDim: "#A69AD6",
  purpleDark: "#30008F", // Should be actionUI
  purpleGradient: "#27038C", // Should be actionUI
  purpleDarkest: "#1E0660",
  purpleDarkestDim: "#6f3f8fb3",
  red: "#FB4B4B",
  redLight: "#e85953",
  redLightest: "#fadedd",
  redDark: "#C80404",
  blue: "#3068EB",
  blueLight: "#4BA9FB",
  blueLightest: "#D6E1FB",
  blueDark: "#046DC8",
  blueSoft: "#C2C2FF",
  skyBlue: "#d7e1fc",
  skyBlueLight: "#f2f5f8",
  yellow: "#F7D13B",
  yellowSoft: "#F8D64F",
  yellowDark: "#C49E08",
  yellowLight: "#F7E7A4",
  orangeLight: "#FCF7E1",
  green: "#8BCE50",
  greenLightest: "#f7fcf3",
  greenDark: "#629F2D",
  greenSoft: "#A0D770",
  violetLight: "#6f3ff326",
  serviceTraining: "#EC9F9C",
  serviceWorkshops: "#9DB5EE",
  servicePlatform: "#A587F7",
  primary: "#6F3FF3",
  primaryDark: "#3C0CC0",
  disabled: "#D6D6D6",
  textDisabled: "#7A7A7A",
  textDark: "#1D1636",
  primaryLight: "#eae2fd",
  transparent: "transparent",
}
