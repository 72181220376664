// Utils
import colors from "../styles/colors"

/**
 * Determines the color of the language link based on its context and whether the language is Dutch.
 *
 * @param {boolean} isLangDutch - This parameter is used to specify if the language is Dutch. If it is true, the language is Dutch.
 * @param {"footer" | "header"} context - This parameter is used to define the context in which the language link is being used. It can either be 'footer' or 'header'.
 *
 * @returns {string} - The return value is a string which is the color for the language link. The color is determined based on the language and the context. For the footer, it returns either `colors.whitePurple` or `colors.whiteGrey`. For the header, it returns either `colors.purpleLight` or `colors.black`.
 */
const getLangColor = (
  isLangDutch: boolean,
  context: "footer" | "header"
): string => {
  if (context === "footer") {
    return !isLangDutch ? colors.whitePurple : colors.whiteGrey
  }

  return isLangDutch ? colors.purpleLight : colors.black
}

export default getLangColor
