import React, { FC } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

// Components
import { sharedLinkStyling } from "./atoms/Link"

// Styling
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

// Utils
import removeLocale from "../utils/removeLocale"
import addLocale from "../utils/addLocale"
import getLangColor from "../utils/getLangColor"

// Types
interface IProps {
  currentPathname: string
  className?: string
  context?: "footer" | "header"
}

const LocaleSwitcher: FC<IProps> = ({
  currentPathname,
  className,
  context = "header",
}) => {
  const isLangEnglish = currentPathname.includes("/en")

  return (
    <LocaleSwitcherWrapper className={className} data-name="locale-switcher">
      <StyledLink
        hrefLang="en-nl"
        to={addLocale(currentPathname, "en")}
        color={getLangColor(isLangEnglish, context)}
        hasstyling={1}
        context={context}
      >
        EN
      </StyledLink>{" "}
      |{" "}
      <StyledLink
        hrefLang="nl-nl"
        to={removeLocale(currentPathname, "en")}
        color={getLangColor(!isLangEnglish, context)}
        hasstyling={1}
        context={context}
      >
        NL
      </StyledLink>
    </LocaleSwitcherWrapper>
  )
}

interface IStyledLinkProps {
  color: string
  hasstyling: number
  context?: "footer" | "header"
}
const StyledLink = styled(Link)<IStyledLinkProps>`
  ${sharedLinkStyling};

  ${mq.to.M`
  font-size: 16px;
  font-weight: 500;
  `}

  ${mq.from.M`
  font-size: 18px;
  font-weight: 500;
  `}

  &:hover {
    color: ${({ context }) =>
      context === "footer" ? colors.white : colors.purpleLight};
    &::after {
      display: none;
    }
  }
`
const LocaleSwitcherWrapper = styled.div``

export default LocaleSwitcher
