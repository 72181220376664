// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investors-tsx": () => import("./../../../src/pages/investors.tsx" /* webpackChunkName: "component---src-pages-investors-tsx" */),
  "component---src-pages-method-tsx": () => import("./../../../src/pages/method.tsx" /* webpackChunkName: "component---src-pages-method-tsx" */),
  "component---src-pages-platform-evaluation-tsx": () => import("./../../../src/pages/platform/evaluation.tsx" /* webpackChunkName: "component---src-pages-platform-evaluation-tsx" */),
  "component---src-pages-platform-index-tsx": () => import("./../../../src/pages/platform/index.tsx" /* webpackChunkName: "component---src-pages-platform-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-services-career-framework-tsx": () => import("./../../../src/pages/services/career-framework.tsx" /* webpackChunkName: "component---src-pages-services-career-framework-tsx" */),
  "component---src-pages-services-culture-support-tsx": () => import("./../../../src/pages/services/culture-support.tsx" /* webpackChunkName: "component---src-pages-services-culture-support-tsx" */),
  "component---src-pages-services-employee-journey-tsx": () => import("./../../../src/pages/services/employee-journey.tsx" /* webpackChunkName: "component---src-pages-services-employee-journey-tsx" */),
  "component---src-pages-services-feedback-training-tsx": () => import("./../../../src/pages/services/feedback-training.tsx" /* webpackChunkName: "component---src-pages-services-feedback-training-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-manifesto-tsx": () => import("./../../../src/pages/services/manifesto.tsx" /* webpackChunkName: "component---src-pages-services-manifesto-tsx" */),
  "component---src-pages-services-mission-statement-tsx": () => import("./../../../src/pages/services/mission-statement.tsx" /* webpackChunkName: "component---src-pages-services-mission-statement-tsx" */),
  "component---src-pages-services-team-lead-training-tsx": () => import("./../../../src/pages/services/team-lead-training.tsx" /* webpackChunkName: "component---src-pages-services-team-lead-training-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-resource-post-tsx": () => import("./../../../src/templates/resource-post.tsx" /* webpackChunkName: "component---src-templates-resource-post-tsx" */)
}

